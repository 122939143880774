import React from "react";
import { Box } from "@material-ui/core";
import ReactMasonry from "react-masonry-css";

import "./styles.css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const Masonry = ({ children }) => {
  return (
    <Box sx={{ width: "100%", minHeight: 829 }}>
      <ReactMasonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {children}
      </ReactMasonry>
    </Box>
  );
};

export default Masonry;

import React from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";

const RaisedContent = ({ children, elevation = 8 }) => {
  const Content = styled(Paper)`
    ${({ theme }) => `
      position: relative;
      z-index: 3;
      margin: -60px 15px 0px;
        padding: 60px 15px;
      background-color: ${theme.palette.primary.background};
      ${theme.breakpoints.up("xs")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("sm")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("md")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("lg")} {
        margin: -60px 30px 0px;
        padding: 60px 30px;
      }
    `}
  `;

  return <Content elevation={elevation}>{children}</Content>;
};

export default RaisedContent;

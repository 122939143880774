import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Masonry from "../components/Masonry";
import RaisedContent from "../components/RaisedContent";
import Banner from "../components/Banner";
import Section from "../sections/Section";

import contentfulServices from "./../services";

const Wrapper = styled.div`
  overflow: hidden; // important
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServiceTemplate = (props) => {
  const { title, heroImage, gallery, body } = get(
    props,
    "data.contentfulServices"
  );
  const href = get(props, "location.href");
  return (
    <Layout location={props.location}>
      <div style={{ background: "#fff" }}>
        <Helmet>
          <title>{`${title} | Diamantidou School of Music, Dance & Drama`}</title>
          {href && <link rel="canonical" href={href} />}
        </Helmet>
        <Banner title={title} imageUrl={_.get(heroImage, "file.url")} />
        <RaisedContent>
          <Wrapper>
            <Section>
              {body && contentfulServices.renderRichText(body.json)}

              {gallery && (
                <Masonry>
                  {gallery.map((item) => (
                    <Img {...item} />
                  ))}
                </Masonry>
              )}
            </Section>
          </Wrapper>
        </RaisedContent>
      </div>
    </Layout>
  );
};

export default ServiceTemplate;

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    contentfulServices(slug: { eq: $slug }) {
      title
      body {
        json
      }
      heroImage {
        file {
          contentType
          url
        }
        fluid(background: "rgb:000000", resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid
        }
      }
      gallery {
        fluid(background: "rgb:000000", resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
